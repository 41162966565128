import { EnvironmentData } from '@cf/core';

export const environment: EnvironmentData = {
  production: true,
  enabledNamespaces: [],
  apiUrl: 'https://api.calfrenzy.com/',
  appUrl: 'https://schedule.calfrenzy.com',
  publicUrl: 'https://calfrenzy.com/',
  domain: '.calfrenzy.com',
  envName: 'prod',
  googleClientId:
    '369573383535-jtjq1arqc3220nbnn0j05ke4glrpr2h0.apps.googleusercontent.com',
  googleMapId: 'AIzaSyA-JbP7RYVDwqF3aAxVavSpcAHq67FfUVU',
  zoomClientId: 'L0KrA52XRYix0ytG7b7hSQ',
  buildVersion: 'b065c33e94568427026f19d60a6ef4656589ebc7',
};
